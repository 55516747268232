<template>
<div class="deliver-manage">
  <div style="position: sticky; z-index: 20; top: 0;">
    <search-bar-new
      :placeholder-text="$t('客户手机号后4位/车辆VIN码/订单号/客户姓名全称')"
      :active="Object.values(filterParams).length > 0"
      :str="searchStr"
      :showScreen="true"
      :showCategory="isShopManager && activeTab != 1"
      :actions="actions"
      @input="onInput"
      @search="onRefresh"
      @screening="filterPanelVisible = true"
      @operation="operation">
    </search-bar-new>
    <!-- 筛选面板 -->
    <FilterPanel
      ref="filterPanel"
      v-model="filterPanelVisible"
      :options="filterOptions"
      @submit="onFilter"
    />
    <!-- 只有交付店长才展示待e39de3 -->
    <VanTabs
      :queryType="activeTab"
      :tabList="tabList"
      @change="changeTab">
    </VanTabs>
  </div>
  <!-- 员工列表面板 -->
  <BusinessFilters ref="filter" v-model="showDistribution"
    :options="distributionOptions"
    :defaultActiveKey="['saIdList']"
    :buttonNameReset="$t('取消')"
    @reset="showDistribution=false"
    @submit="distributionFilter">
  </BusinessFilters>
  <!-- a8b0c2tab才展示 -->
  <p v-if="activeTab===0" class="total">{{$t('共')}}{{ tabList[0].total  }}{{$t('条数据')}}</p>
  <List ref="list" :fetchApi="fetchApi">
    <template v-slot:default="{ item, index }">
      <deliver-item
        :dataSource="item"
        :isDistributionState="isDistributionState|| waitDistribution"
        @goDetail="goDetail(item)"
        @change="onCheckChange"/>
    </template>
  </List>
  <div v-if="isDistributionState || waitDistribution" class="action-bar">
    <div class="left">
      <span @click="cleanDistribution">{{$t('取消')}}</span>
      <span @click="onAllChecked">{{$t('全选')}}</span>
    </div>
    <div class="right">
      <van-button square color="#EED484" @click="onDistribution">
        {{$t('分配')}}{{ distributionIds.length ? `(${distributionIds.length})` : '' }}
      </van-button>
    </div>
  </div>
</div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import FilterPanel from '@/components/filter-panel'
import VanTabs from '@/components/VanTabs'
import List from '@/components/baseList/list'
import loading from '@/utils/loading'
import DeliverItem from './component/deliver-item'
import deliveryServices from '@/services/deliveryServices.js'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import baseDataServices from '@/services/baseDataServices.js'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import { parseQuery } from '@/utils'
import formatParams from '@/utils/formatParams'
import dayjs from 'dayjs'
import salesAssistantService from '@/services/salesAssistant'

export default {
  name: 'deliverManage',
  components: { SearchBarNew,FilterPanel,VanTabs,List,DeliverItem,BusinessFilters },
  props: {
    activeTabVal: {
      type: Number,
      default: () => 0
    }
  },
  data(){
    return {
      searchStr: '',
      filterParams: {},
      filterPanelVisible: false,
      filterOptions:[],
      activeTab: 0,
      tabList:[
        { text: this.$t('全部'), value: 0, total:0 },
        { text: this.$t('已整备'), value: 5, total: 0 , key: 'toDeliveryCount', code: '4000010' },
        { text: this.$t('已PDI'), value: 3, total: 0 , key: 'toBookCount', code: '4000005' },
        { text: this.$t('已入库'), value: 2, total: 0 , key: 'pdiCount', code: '4000004' },
        { text: this.$t('已发运'), value: 10, total: 0 , key: 'shippedCount', code: '4000003' },
        { text: this.$t('已下线'), value: 15, total: 0 , key: 'productCount', code: '4000002' },
        { text: this.$t('已排产'), value: 18, total: 0 , key: 'productIn', code: '4000001' },
        { text: this.$t('未排产'), value: 20, total: 0 , key: 'toProductCount', code: '4000080' },
        // { text: this.$t('已锁单'), value: 25, total: 0 , key: 'lockedCount', code: '4000000_1' },
        // { text: this.$t('已大定'), value: 30, total: 0 , key: 'toLockCount', code: '4000000_2' },
        { text: this.$t('休眠'), value: 35, total: 0 , key: 'sleepCount', code: '4000085' },
        { text: this.$t('已交车'), value: 8, total: 0 , key: 'deliveredCount', code: '4000008' },
      ],
      isDistributionState: false,
      distributionIds: [],
      actions:[{ text: this.$t('重新分配'), key: 'distribute' }],
      showDistribution: false,
      latestDeliveryTask: '',
      deliverTimeType: '',
      appType: '',
      roleCode: '', // 交付助手首页跳转会携带角色
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { 
        latestDeliveryTask = '', 
        deliverTimeType = '', 
        appType = '',
        queryTypeCode
      } = to.query
      vm.appType = appType
      vm.activeTab = vm.tabList.find(({ code }) => { return code === queryTypeCode })?.value
      if (latestDeliveryTask || deliverTimeType) {
        vm.latestDeliveryTask = latestDeliveryTask || ''
        if (latestDeliveryTask) vm.filterParams.latestDeliveryTask = [latestDeliveryTask]
        if (deliverTimeType) {
          vm.deliverTimeType = deliverTimeType
          vm.getDeliverTime(deliverTimeType)
        }
      } else {
        // vm.$store.commit('app/addKeepAlive', 'deliverManage')
      }
      vm.$store.commit('app/addKeepAlive', 'deliverManage')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.filterParams = {}
      this.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'deliverManage')
    }
    next()
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      getMenu:'app/getMenu',
      dictHash: 'dictHash'
    }),
    distributionOptions(){
      return [{
        name: '',
        type: 'B_PEOPLE_SELECTION',
        field: 'saIdList',
        isSelection: true,
        valueType: 'object',
        shopIds:'',
        height:'80vh',
        roleCodes: '1014006' // 154ce1的角色
      }]
    },
    // 是否是交付店长
    isShopManager(){
      return (this.userInfo?.roleLogos||[]).includes('1014007')
    },
    waitDistribution() {
      if (!this.getMenu) return false
      const { agentId } = parseQuery(window.location.search || '')
      const { btn = {} } = JSON.parse(this.getMenu)
      if (this.activeTab === 1 && btn[`${agentId}-BTN-DELIVEY-WAITDISTRIBUTE`]) {
        return true
      }
      return false
    },
  },
  watch:{
    userInfo:{
      handler(val){
        const { roleLogos=[] } = val
        if (roleLogos.includes('1014007')) {
          this.tabList.splice(1,0,{ text: this.$t('待分配'), value: 1, total: 0 , key:'toAssignCount' })
        }
      },
      immediate: true,
    },
    dictHash: {
      async handler(val) {
        // 过滤条件
        val && await this.createFilterOptions()
      },
      immediate: true,
    },
    activeTabVal: {
      handler(val) {
        if (val) {
          this.activeTab = val
        }
      },
      immediate: true
    },
    latestDeliveryTask: {
      handler(val) {
        if (val) {
          this.createFilterOptions()
        }
      }
    },
  },
  created(){
    this.handleQuery()
  },
  mounted() {
    this.getCount()
    // this.onRefresh()
  },
  activated() {
    this.handleQuery()
    const { queryTypeCode } = this.$route.query
    if (queryTypeCode) this.activeTab = this.tabList.find(({ code }) => { return code === queryTypeCode })?.value 
    this.onRefresh()
  },
  methods:{
    handleQuery(){
      const {
        bookDeliveryTimeStart = '',
        bookDeliveryTimeEnd = '',
        deliveryStatuses = [],
        roleCode = '',
        parentCode = '',
        childCode = '',
        dealerProvinceCode = '',
        dealerCityCode = '',
        dealerIds = ''
      } = this.$route.query
     
      parentCode && this.$set(this.filterParams,'parentCode', parentCode)
      childCode && this.$set(this.filterParams,'childCode', childCode.split(','))
      dealerProvinceCode && this.$set(this.filterParams,'dealerProvinceCode', dealerProvinceCode)
      dealerCityCode && this.$set(this.filterParams,'dealerCityCode', dealerCityCode)
      dealerIds && this.$set(this.filterParams,'dealerIds', dealerIds.split(','))
      // 角色
      this.roleCode = roleCode
      // 预约交车时间
      if (bookDeliveryTimeStart || bookDeliveryTimeEnd){
        this.$set(this.filterParams,'bookStartTime',bookDeliveryTimeStart)
        this.$set(this.filterParams,'bookEndTime',bookDeliveryTimeEnd)
      }
      // 交车状态
      if (deliveryStatuses.length){
        this.$set(this.filterParams,'deliveryStatuses',deliveryStatuses)
      }
      // 设置漏斗 
      setTimeout(() => {
        if (bookDeliveryTimeStart) this.$set(this.$refs.filterPanel.formData,'bookStartTime',dayjs(bookDeliveryTimeStart).toDate())
        if (bookDeliveryTimeEnd) this.$set(this.$refs.filterPanel.formData,'bookEndTime',dayjs(bookDeliveryTimeEnd).toDate())
        if (deliveryStatuses.length){
          const list = this.dictHash[4000].filter(e=>deliveryStatuses.includes(e.code)).map(e=>({
            id: e.id,
            label: e.name,
            value: e.code
          }))
          this.$set(this.$refs.filterPanel.formData,'deliveryStatuses',list)
        }
      }, 1000)
    },
    async getCount(){
      const params = {
        ...this.filterParams,
        searchStr: this.searchStr,
        roleCode: this.roleCode
      }
      if (this.appType) params.appType = this.appType
      if (this.filterParams.bookStartTime || this.filterParams.bookEndTime) {
        params.bookDeliveryTime = {}
        params.bookDeliveryTime.start = this.filterParams.bookStartTime || ''
        params.bookDeliveryTime.end = this.filterParams.bookEndTime || ''
      }
      if (this.filterParams.deliveryStartTime || this.filterParams.deliveryEndTime) {
        params.deliveryTime = {}
        params.deliveryTime.start = this.filterParams.deliveryStartTime
        params.deliveryTime.end = this.filterParams.deliveryEndTime
      }
      if (this.filterParams.orderCreatedTimeStartTime || this.filterParams.orderCreatedTimeEndTime) {
        params.orderCreatedTime = {}
        params.orderCreatedTime.start = this.filterParams.orderCreatedTimeStartTime
        params.orderCreatedTime.end = this.filterParams.orderCreatedTimeEndTime
      }
      let tabCount = await deliveryServices.getDeliverCount(params)
      this.tabList.forEach(item => {
        if (item.key) item.total = tabCount[item.key] || 0
      })
    },
    onInput(value) {
      this.searchStr = value
    },
    onRefresh(){
      this.getCount()
      this.$refs.list.onRefresh()
    },
    operation({ key }){
      if (key==='distribute'){
        this.isDistributionState = true
      }
    },
    onFilter(params){
      this.filterParams = formatParams(params, false)
      this.filterPanelVisible = false
      this.onRefresh()
    },
    changeTab(val){
      this.activeTab=val
      this.distributionIds = []
      let url = new URL(window.location.href)
      let params = new URLSearchParams(url.search)
      params.set('queryTypeCode', this.tabList.find(({ value }) => { return value === val })?.code || '')
      url.search = params.toString()
      window.history.replaceState(null,'',url.href)
      this.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj={}) {
      const params = {
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr,
        queryType: this.activeTab,
        roleCode: this.roleCode
      }
      if (this.appType) params.appType = this.appType
      if (this.filterParams.bookStartTime || this.filterParams.bookEndTime) {
        params.bookDeliveryTime = {}
        params.bookDeliveryTime.start = this.filterParams.bookStartTime || ''
        params.bookDeliveryTime.end = this.filterParams.bookEndTime || ''
      }
      if (this.filterParams.deliveryStartTime || this.filterParams.deliveryEndTime) {
        params.deliveryTime = {}
        params.deliveryTime.start = this.filterParams.deliveryStartTime
        params.deliveryTime.end = this.filterParams.deliveryEndTime
      }
      if (this.filterParams.orderCreatedTimeStartTime || this.filterParams.orderCreatedTimeEndTime) {
        params.orderCreatedTime = {}
        params.orderCreatedTime.start = this.filterParams.orderCreatedTimeStartTime
        params.orderCreatedTime.end = this.filterParams.orderCreatedTimeEndTime
      }
      loading.showLoading()
      return deliveryServices.getDeliverList(params).then(res => {
        loading.hideLoading()
        res.dataList.forEach((item)=>{
          item.checked=false
        })
        if (this.activeTab===0) this.tabList[0].total = res.page.totalCount
        return res
      })
    },
    // 搜索面板条件
    async createFilterOptions(){
      const ret = []
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        let list = dictHash[dictType] || []
        if (dictType === 4004) {
          list = list.filter(({ code }) => { return ['4004002', '4004003', '4004001'].includes(code) })
          const sortList = ['4004002', '4004003', '4004001']
          list = list.sort((a, b) => {
            return sortList.indexOf(a.code) - sortList.indexOf(b.code)
          })
        }
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      if (this.$store.getters.userInfo.type === 0) {
        const { parentCode='',
          childCode='',
          dealerProvinceCode='',
          dealerCityCode='',
          dealerIds=''
        } = this.$route.query
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          defaultValue: parentCode,
          options: [],
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          field: 'childCode',
          defaultValue: childCode,
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('门店省份'),
          field: 'dealerProvinceCode',
          defaultValue: dealerProvinceCode,
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('门店城市'),
          field: 'dealerCityCode',
          defaultValue: dealerCityCode,
          options: [],
        })
        // 交付门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '', dealerType: 2 })
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('交付门店'),
          multiple: true,
          field: 'dealerIds',
          defaultValue: dealerIds,
          dealerType: 2,
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      const seriesList = await baseDataServices.getAllSeriesModels()
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('车系车型'),
        field: 'seriesCodes',
        multiple: true,
        options: seriesList.map(({ code, name, models }) => ({ id: code, label: name, value: code, modelList: models })),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'modelCodes',
        multiple: true,
        options: [],
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: '',
        label: this.$t('预计交付时间'),
        start: {
          field: 'bookStartTime',
        },
        end: {
          field: 'bookEndTime',
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: '',
        label: this.$t('实际交车时间'),
        start: {
          field: 'deliveryStartTime',
          value: this.deliveryStartTime
        },
        end: {
          field: 'deliveryEndTime',
          value: this.deliveryEndTime
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('付款方式'),
        field: 'installmentsFlag',
        multiple: true,
        options: [
          { id: uuidv4(), value: 0, label: this.$t('全款') },
          { id: uuidv4(), value: 1, label: this.$t('分期') }
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('交付方式'),
        field: 'deliveryMethod',
        multiple: true,
        options: getOptionsByDictType(4007),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('金融状态'),
        field: 'financeStatus',
        multiple: true,
        options: getOptionsByDictType(4005),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('PDI状态'),
        field: 'pdiStatus',
        multiple: true,
        options: getOptionsByDictType(4004),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('待办状态'),
        field: 'latestDeliveryTask',
        multiple: true,
        defaultValue: this.latestDeliveryTask ? [{ 
          id: (dictHash[4012] || []).find(({ code }) => { return code === this.latestDeliveryTask })?.id,
          value: this.latestDeliveryTask,
        }] : [],
        options: getOptionsByDictType(4012),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('交付单与车辆状态'),
        field: 'deliveryStatuses',
        multiple: true,
        options: getOptionsByDictType(4000),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('发票状态'),
        field: 'invoiceStatuses',
        multiple: true,
        options: getOptionsByDictType(2024)
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('保险状态'),
        field: 'policyStatus',
        multiple: true,
        // options: [
        //   { id: uuidv4(), value: 0, label: this.$t('未申请') },
        //   { id: uuidv4(), value: 1, label: this.$t('待分配') },
        //   { id: uuidv4(), value: 2, label: this.$t('已分配待处理') },
        //   { id: uuidv4(), value: 4, label: this.$t('承保') },
        //   { id: uuidv4(), value: 5, label: this.$t('战败') },
        // ],
        options: getOptionsByDictType(4002)
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('总部锁定状态'),
        field: 'portalLockStatus',
        multiple: false,
        options: [
          { id: uuidv4(), value: 0, label: this.$t('未锁定') },
          { id: uuidv4(), value: 1, label: this.$t('已锁定') },
          { id: uuidv4(), value: 2, label: this.$t('已解锁') },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: '',
        label: this.$t('下单时间'),
        start: {
          field: 'orderCreatedTimeStartTime',
        },
        end: {
          field: 'orderCreatedTimeEndTime',
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '订单类型',
        field: 'orderType',
        options: [
          { id: 3, value: 3, label: 'C端订单' },
          { id: 1, value: 1, label: '大客户订单' },
          { id: 2, value: 2, label: '经销商试驾车' },
          // { id: 4, value: 4, label: 'SCRM代客零售' },
          { id: 5, value: 5, label: '大客户试驾车' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '订单类型细分',
        field: 'coVehicleSource',
        options: [
          { id: 2, value: 2, label: '特殊车（非一车一价）' },
          { id: 1, value: 1, label: '普通商品车' },
        ],
      })
      // 获取交付专员
      const dealerId = this.userInfo?.dealers?.length&&this.userInfo.dealers[0].id
      if (dealerId){
        const params = {
          dealerId: dealerId,
          roleLogo: '1014006' // 交付专员的角色
        }
        const seriesList = await baseDataServices.getStaffList(params)
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('交付专员'),
          field: 'deliveryUserIds',
          multiple: true,
          options: seriesList.map(({ id, name }) => ({ id, label: name, value: id })),
        })
      }
      this.filterOptions = ret
      if (this.deliverTimeType) {
        setTimeout(() => {
          this.getDeliverTime(this.deliverTimeType)
        }, 200)
      }
    },
    onCheckChange(checked,theItem){
      const { followUserRoleCode, id } = theItem
      const ids = []
      this.$refs.list.list.forEach(item=>{
        if (item.id === id){
          item.checked=checked
        }
        if (item.checked) ids.push(item.id)
      })
      this.distributionIds = ids
    },
    // 取消全选
    cleanDistribution(){
      this.distributionIds = []
      this.$refs.list.list.forEach(item=>{
        item.checked = false
      })
      this.isDistributionState = false
    },
    // 全选
    onAllChecked(){
      const ids = []
      this.$refs.list.list.forEach(item=>{
        item.checked = true
        ids.push(item.id)
      })
      this.distributionIds = ids
    },
    // 打开员工选择面板
    onDistribution(){
      if (!this.distributionIds.length) return this.$toast(this.$t('请选择待分配的交车单'))
      this.showDistribution = true
    },
    async distributionFilter({ saIdList }){
      const [{ id,name }] = saIdList
      const params = {
        deliveryUserId: id,
        deliveryUserName: name,
        orderIds: this.distributionIds,
        type: this.activeTab===0 ? 1 : 0// 0分配 1重新分配
      }
      const res = await deliveryServices.deliverAllocationPost(params)
      if (this.activeTab === 0) this.isDistributionState = false
      this.distributionIds = []
      this.onRefresh()
    },
    goDetail({ id }) {
      this.$router.push({
        path: '/deliver-detail',
        query: {
          id,
        },
      })
    },
    // 计算当前周
    getCurrentWeek() {
      const start = this.$moment()
        .isoWeekday(1)
        .format('YYYY-MM-DD 00:00:00') // 本周一
      const end = this.$moment()
        .isoWeekday(7)
        .format('YYYY-MM-DD 23:59:59') // 本周日
      this.filterParams.deliveryStartTime = start
      this.filterParams.deliveryEndTime = end
      this.$refs.filterPanel.formData.deliveryStartTime = dayjs(start).toDate()
      this.$refs.filterPanel.formData.deliveryEndTime = dayjs(end).toDate()
    },
    // 从交付助手跳转过来 计算实际交车时间
    getDeliverTime(type) {
      if (type == 'today') {
        this.filterParams.deliveryStartTime = dayjs().format('YYYY-MM-DD 00:00:00')
        this.filterParams.deliveryEndTime = dayjs().format('YYYY-MM-DD 23:59:59')
        this.$refs.filterPanel.formData.deliveryStartTime = dayjs().toDate()
        this.$refs.filterPanel.formData.deliveryEndTime = dayjs().toDate()
      } else if (type == 'week') {
        this.getCurrentWeek()
      } else {
        const start = this.$moment()
          .startOf(type)
          .format('YYYY-MM-DD 00:00:00')
        const end = this.$moment()
          .endOf(type)
          .format('YYYY-MM-DD 23:59:59')
        this.filterParams.deliveryStartTime = start
        this.filterParams.deliveryEndTime = end
        this.$refs.filterPanel.formData.deliveryStartTime = dayjs(start).toDate()
        this.$refs.filterPanel.formData.deliveryEndTime = dayjs(end).toDate()
      }
    }
  }
}
</script>
<style lang="less" scoped>

.total{
  height: 22px;
  font-size: 14px;
  font-family: HYQiHei, HYQiHei;
  font-weight: normal;
  color: #262626;
  line-height: 22px;
  margin: 9px 16px 0;
}

.action-bar{
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    background: #FFFFFF;
    z-index: 999;

    .left{
      padding-left: 16px;
      span{
        display: inline-block;
        min-width: 28px;
        height: 22px;
        line-height: 22px;
        margin: 0 8px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
      }
    }

    /deep/.van-button{
      color: #0D171A !important;
      width: 92px;
      font-weight: 500;
    }
  }
</style>
